<script setup lang="ts">
import { UI05StatusBlock } from 'ui-05'
import { storeToRefs } from 'pinia'
import { useOrderModalStore } from '~/store/modal/order'
import { useUserStore } from '~/store/user'
import { useServiceStore } from '~~/store/service'
import { useAddressStore } from '~/store/address'
import { useChooseAddressStore } from '~/store/modal/chooseAddress'
import DeliveryDelayedNote from '~/components/DeliveryDelayedNote/DeliveryDelayedNote.vue'
import { useCartStore } from '~/store/cart'

const { $api } = useNuxtApp()

const { isUser } = storeToRefs(useUserStore())
const orderModalStore = useOrderModalStore()
const { isDeliveryDelayed, serviceUnavailable, showDeliveryDelayNotification } = storeToRefs(useServiceStore())
const { setShowDeliveryDelayNotification } = useServiceStore()
const { current: address } = storeToRefs(useAddressStore())
const { enable: chooseAddressMapEnable } = useChooseAddressStore()
const cartStore = useCartStore()

const main = await useDataEntity('main-page', $api.constructor.main, {
  params: isUser,
  watchParam: true,
})

useHead({
  title: 'Доставка продуктов на дом от 05ру - Близко 05ру',
  meta: [
    {
      hid: 'description',
      name: 'description',
      content: 'Доставка продуктов из 05ru ✔ Закажите ваши любимые товары на сайте, и мы доставим их к вам домой ✔ Будьте уверены: мы тщательно следим за свежестью и качеством продуктов',
    },
  ],
})

onMounted(() => {
  if (isDeliveryDelayed.value)
    setShowDeliveryDelayNotification()
})
</script>

<template>
  <div class="main-page">
    <UI05StatusBlock
      v-if="main.loading.value"
      size="l"
      type="loading"
    />
    <UI05StatusBlock
      v-else-if="main.error.value"
      size="l"
      type="error"
      title="При загрузке страницы произошла ошибка, попробуйте позже"
    />
    <div v-else-if="!serviceUnavailable">
      <div class="main-page__info-blocks">
        <div
          v-if="main.data.value && main.data.value.order.length > 1"
          class="main-page__factoids-row main-page__factoids-row_orders"
        >
          <ViewportToogle>
            <template #desktop>
              <RowSlider
                v-slot="{ el }"
                :list="main.data.value.order"
                :offset-between-elements="8"
                :slides-per-group="1"
              >
                <div class="main-page__factoid-wrap">
                  <OrderStatusFactoid
                    :order="el"
                    @detail-click="orderModalStore.enable({
                      data: el,
                      onAccess: () => main.request(),
                    })"
                  />
                </div>
              </RowSlider>
            </template>
            <template #mobile>
              <RowSlider
                v-slot="{ el }"
                :list="main.data.value.order"
                :offset-between-elements="10"
                :slides-per-group="1"
                :hide-controls="true"
              >
                <div class="main-page__factoid-wrap">
                  <OrderStatusFactoid
                    :order="el"
                    @detail-click="orderModalStore.enable({
                      data: el,
                      onAccess: () => main.request(),
                    })"
                  />
                </div>
              </RowSlider>
            </template>
          </ViewportToogle>
        </div>
        <div
          v-else-if="main.data.value && main.data.value.order.length"
          class="main-page__factoids-row main-page__factoids-row_orders"
        >
          <div class="main-page__factoid">
            <OrderStatusFactoid
              :order="main.data.value.order[0]"
              @detail-click="main.data.value?.order.length && orderModalStore.enable({
                data: main.data.value?.order[0],
                onAccess: () => main.request(),
              })"
            />
          </div>
        </div>
        <div v-if="cartStore.serviceType" class="main-page__factoids-row">
          <ServiceFactoid
            :type="cartStore.serviceType"
            page="main"
            class="main-page__service-factoid"
          />
        </div>
        <div class="main-page__factoids-row">
          <div v-if="main.data.value?.storyBanner?.length" class="main-page__factoid main-page__factoid_stories">
            <StoryBanner :list="main.data.value?.storyBanner" />
          </div>
          <div class="main-page__factoid">
            <MainActiveBlock class="main-page__active-block" />
          </div>
        </div>
        <div v-if="main.data.value?.recipes?.length" class="main-page__factoids-row">
          <RecipesList :list="main.data.value?.recipes" />
        </div>
        <template v-if="main.data.value?.selection?.length">
          <SelectionList :list="main.data.value.selection" />
        </template>
      </div>
      <div>
        <div class="main-page__wrap mt-20">
          <div class="main-page__categories-wrap">
            <CategoryMainGrid v-if="main.data.value?.category" :list="main.data.value?.category" />
          </div>
        </div>
        <div class="main-page__advertising">
          <AppAdvertising />
        </div>
        <template v-if="main.data.value?.question.length">
          <div class="main-page__questions">
            <h1 class="main-page__questions-title">
              Остались вопросы? Отвечаем
            </h1>
            <UserQuestion :list="main.data.value?.question" :limit="3" />
          </div>
        </template>
        <div class="main-page__privacy">
          <nuxt-link class="main-page__privacy-link" to="/about/terms-of-use/">
            Пользовательское соглашение
          </nuxt-link>
          <nuxt-link class="main-page__privacy-link" to="/about/privacy/">
            Политика обработки данных
          </nuxt-link>
        </div>
      </div>
      <DemoNote :active="!address" @open-map="chooseAddressMapEnable" />
      <Transition name="fade">
        <div v-if="address && isDeliveryDelayed && showDeliveryDelayNotification" class="main-page__delivery-note">
          <DeliveryDelayedNote type="large" />
        </div>
      </Transition>
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.main-page {
  width: 100%;

  &__delivery-note {
    position: fixed;
    bottom: 80px;
    left: 50%;
    z-index: var(--z-blackout);
    width: fit-content;
    padding: 15px;
    font-weight: 500;
    text-wrap: nowrap;
    background-color: white;
    border-radius: 20px;
    transform: translate(-50%, 0);
  }

  &__advertising {
    margin: 44px 0 40px;

    @media (max-width: 1000px) {
      margin: 24px 0;
    }
  }

  &__line {
    display: none;
  }

  &__service-factoid {
    display: none;
  }

  &__factoid {
    flex-grow: 1;
    width: 100%;
    margin-right: 15px;

    &-wrap {
      min-width: 430px;
    }

    &_stories {
      min-width: 453px;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  &__category:first-child {
    margin-bottom: 10px;
  }

  &__questions {

    &-title {
      margin-bottom: 26px;
      font-size: 32px;
      font-weight: 500;
      line-height: 130%;
    }

    @media (max-width: 1000px) {

      &-title {
        font-size: 24px;
      }
    }
  }

  &__info-blocks {
    display: flex;
    flex-grow: 1;
    flex-wrap: wrap;
  }

  &__factoids-row {
    position: relative;
    display: flex;
    flex-grow: 1;
    align-items: center;
    width: 100%;
    margin-bottom: 20px;

    &_orders {
      height: 100px;
      margin-bottom: 20px;
    }

    &_grid {
      display: none;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  @media (max-width: 1400px) {

    &__factoid {
      margin-right: 0;

      &:first-child {
        margin-bottom: 20px;
      }
    }

    &__factoids-row {
      flex-direction: column;
      align-items: inherit;

      &_orders {
        height: 100px;
      }
    }

    &__line {
      display: block;
      width: 100%;
      height: 1px;
      color: #F3F3F3;
      content: '';
      opacity: 0.3;
    }
  }

  @media (max-width: 1000px) {

    &__service-factoid {
      display: block;
    }

    &__delivery-note {
      width: calc(100% - 30px);
    }

    &__wrap {
      margin-bottom: 15px;
    }

    &__factoids-row {

      &_orders {
        height: 107px;
        margin-bottom: 10px;
      }

      &_desktop {
        display: none;
      }

      &_grid {
        display: grid;
        grid-template-rows: repeat(2, 1fr);
        grid-template-columns: repeat(3, 1fr);
        gap: 5px;
        max-height: 150px;

        ^^&__factoid {
          margin-bottom: 0;

          &:first-child {
            grid-row-start: 1;
            grid-row-end: 3;
            grid-column-start: 1;
            grid-column-end: 2;
            margin-bottom: 0;
          }

          &:nth-child(2) {
            grid-row-start: 1;
            grid-row-end: 2;
            grid-column-start: 2;
            grid-column-end: 4;
          }

          &:last-child {
            grid-row-start: 2;
            grid-row-end: 3;
            grid-column-start: 2;
            grid-column-end: 4;
          }
        }
      }
    }

    &__factoid {

      &_hide-on-mobile {
        display: none;
      }

      &_stories {
        min-width: auto;
      }

      &:first-child {
        margin: 0 0 15px 0;
      }
    }

    &__categories-wrap {
      margin-top: 23px;
      overflow: hidden;
      border-radius: 10px;
    }
  }

  @media (max-width: 800px) {

    &__category:first-child {
      margin-bottom: 3px;
    }

    &__factoid-wrap {
      min-width: auto;
    }
  }

  &__privacy {
    display: flex;
    gap: 16px;
    margin-top: 100px;

    @media (max-width: 500px) {
      flex-direction: column;
      align-items: center;
      margin-top: 20px;
    }

    &-link {
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      color: #818181;
      text-decoration-color: #E5E5E5;
      text-underline-offset: 3px;
    }
  }
}
</style>
